<template>
	<div class="container">
		<top-header></top-header>
		<div class="database width-1300">
			<div class="top-screen">
				<el-select v-model="value" placeholder="磨机类型">
					<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
				<el-select v-model="value2" placeholder="磨机长度">
					<el-option v-for="item in options" :key="item.value2" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
				<el-select v-model="value3" placeholder="磨机直径">
					<el-option v-for="item in options" :key="item.value3" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>
			<div class="search">
				<input type="text" placeholder="请输入矿山名称搜索">
				<button><i class="iconfont iconsousuo"></i></button>
			</div>
			<data-map :userJson="userJson" @maplist="maplist"></data-map>
			<div class="database-res">
				<p>江西耐普</p>
				<div class="address">
					<div class="left">
						<i class="iconfont icondizhi"></i>
					</div>
					<div class="right">
						<p>矿山名称</p>
						<p>东经：111°35 ~ 114°15</p>
						<p>北纬：111°35 ~ 114°15</p>
					</div>
				</div>
			</div>
			<!-- <div class="upload-btn" @click="gotoUpload">
				<span>上传点云数据</span>
			</div> -->
		</div>
		<bottom-footer></bottom-footer>
	</div>
</template>

<script>
	import topHeader from '../../components/header.vue'
	import bottomFooter from '../../components/footer.vue'
	import dataMap from '../../components/map/mapEcharts1.vue'
	export default {
		components: {
			topHeader,
			bottomFooter,
			dataMap
		},
		data() {
			return {
				userJson: [{
					district: "江西耐普",
					companyname:'中国',
					id: 106132,
					latitude: "35.86166",
					longitude: "104.195397",
				}],
				options: [{
					value: '选项1',
					label: '磨机1'
				}, {
					value: '选项2',
					label: '磨机2'
				}, {
					value: '选项3',
					label: '磨机3'
				}, {
					value: '选项4',
					label: '磨机4'
				}, {
					value: '选项5',
					label: '磨机5'
				}],
				value: '',
				value2: '',
				value3: '',
				searchValue:''
			}
		},
		created() {

		},
		methods: {
			maplist(e) {
				//console.log(e);
				this.$router.push({
					query:{
						id:e[0].id
					},
					name:'databaseinfo'
				})
			},
			gotoUpload() {
				this.$router.push('/databaseupload')
			}
		}
	}
</script>

<style lang="scss">
	.upload-btn {
		position: fixed;
		right: 75px;
		bottom: 260px;
		background: linear-gradient(-36deg, #0064C8, #3995F0);
		color: #fff;
		font-size: 12px;
		cursor: pointer;
		text-align: center;
		width: 90px;
		height: 90px;
		line-height: 90px;
		border-radius: 50%;
		transition: all .5s;

		&:hover {
			box-shadow: 0px 0px 10px 0px #0064C8;
		}

		@media screen and (min-width: 320px) and (max-width: 767px) {
			position: initial;
			width: 80%;
			line-height: initial;
			height: auto;
			padding: 10px 0;
			margin: 0 auto;
			margin-top: 30px;
			background-color: #0058B1;
			border-radius: 10px;
		}
	}

	.database {
		margin-top: 85px;
		min-height: 803px;
		// background: #000;
		// #mapEcharts {
		// 	background: #000;
		// }
		@media screen and (min-width:320px) and (max-width:767px) {
			margin-top: 85px;
		}

		&-res {
			display: none;
			margin-top: 30px;
			border-radius: 5px;
			padding: 10px;
			box-sizing: border-box;
			font-size: 14px;
			box-shadow: 0px 7px 21px 0px rgb(0 0 0 / 15%);

			@media screen and (min-width:320px) and (max-width:767px) {
				display: block;
			}

			.address {
				margin-top: 5px;
				color: #3292F3;

				.left {
					display: inline-block;
					vertical-align: top;
					width: 10%;

					.iconfont {
						font-size: 20px;
					}
				}

				.right {
					display: inline-block;
					vertical-align: top;
					width: 90%;
					line-height: 24px;
				}
			}
		}

		.top-screen {
			display: inline-flex;
			justify-content: space-between;
			width: 50%;
			input {
				border: 0;
			}
		}

		.search {
			border: 1px solid #888;
			border-radius: 40px;
			display: inline-block;
			width: 44%;
			margin-bottom: 40px;
			margin-left: 5%;
			@media screen and(min-width:320px) and (max-width:767px) {
				margin: 20px 10px;
			}	
		
			input {
				// border-top: 1px solid #888;
				// border-bottom: 1px solid #888;
				// border-left: 1px solid #888;
				border-top-left-radius: 40px;
				border-bottom-left-radius: 40px;
				padding: 0 15px;
				box-sizing: border-box;
				border-right: 0;
				width: 87.3%;
				height: 40px;
				vertical-align: middle;
				outline: none;
				transition: border-color .3s;
				background-color: rgba($color: #ebebeb, $alpha: .4);
				border: 0;
		
				&:focus {
					border-color: #0058B1;
				}
		
				@media screen and(min-width:320px) and (max-width:767px) {
					width: 79%;
				}
			}
		
			button {
				border: 0px;
				vertical-align: middle;
				cursor: pointer;
				color: #000;
				width: 12.6%;
				height: 40px;
				background-color: rgba($color: #ebebeb, $alpha: .4);
				border-top-right-radius: 40px;
				border-bottom-right-radius: 40px;
		
				@media screen and(min-width:320px) and (max-width:767px) {
					width: 20.8%;
				}
			}
		}
	}
</style>
